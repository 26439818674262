import React, { useState, useEffect, useRef } from "react"
import TrustpilotReviews from "@pittica/gatsby-plugin-trustpilot-widget"

const TrustPilotWidget = (props) =>{
    return (
        <div className="trustpilot-reviews-list-widget">
            <TrustpilotReviews
            language="en"
            culture="US"
            theme="dark"
            width="100%"
            height="52px"
            template="5419b6ffb0d04a076446a9af"
            business="55674e090000ff00057fbc33"
            username="www.wardsofkent.co.uk"
            />
        </div>
    )
}

export default TrustPilotWidget;
